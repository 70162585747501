.div-principal2 {
  margin-left: 240px;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.select-divider-search2 {
  justify-content: center;
  margin-left: 1%;
  margin-top: 1%;
}

.more-button-align {
  display: flex;
  justify-content: center;
}

.more-button {
  background-color: #f5f5f5;
  border: none;
  color: #cad401;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.semana-div2 {
  background-color: #f5f5f5;
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 10px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.leaflet-container2 {
  width: 100%;
  height: 45%;
}

.bot-div {
  display: flex;
  justify-content: center;
}

.item-map {
  border: none;
  background-color: white;
  margin-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.table-style {
  margin: 30px;
}

.link-stlye {
  color: black;
}
