.title-warehouse {
  display: flex;
  justify-content: center;
  text-align: left;
  padding-top: 10px;
  font-size: 14px;
}

.button-flex-warehouse {
  display: flex;
  justify-content: center;
}

.add-report-button {
  margin-left: 10px;
  width: 170px !important;
  height: 35px;
  background-color: #cad401 !important;
  color: white !important;
  border-radius: 20px !important;
  border: none;
}

.edit-report-button {
  margin-left: 10px;
  width: 120px !important;
  height: 30px;
  background-color: #cad401 !important;
  color: white !important;
  border-radius: 20px !important;
  border: none;
}

.delete-report-button {
  margin-left: 10px;
  width: 35px !important;
  height: 30px;
  background-color: #c45438 !important;
  color: white !important;
  border-radius: 30px !important;
  border: none;
}

.title-form-warehouse {
  font-size: 14px;
}

.subcampos-panel-warehouse {
  padding-left: 3%;
  padding-right: 3%;
  text-align: left;
  background: white;
}

.fields-flex-warehouse {
  display: flex;
  justify-content: left;
  margin-left: 27px;
}

.calibre-tableWA {
  text-align: center;
  width: 575px !important;
  margin-top: 10px !important;
}
