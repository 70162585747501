.select-groupMP {
  margin-top: 20px;
  display: flex;
  background-color: #ffffff;
  height: 45px;
  width: 52.5%;
  padding-top: 6px;
  margin-left: 5%;
}

.multimedia {
  /* margin-top: 20px; */
  display: flex;
  background-color: #ffffff;
  height: 95%;
  /* width: 50%; */
  padding-top: 6px;
  margin-left: 5%;
  object-fit: cover;
}

.multimedia2 {
  /* margin-top: 20px; */
  display: flex;
  background-color: #ffffff;
  height: 180px;
  width: 260px;
  padding-top: 6px;
  object-fit: cover;
  /* margin-left: 5%; */
}

.cardStyle {
  transition: all 0.15s ease-in-out 0s;
}

.cardStyle:hover {
  transform: scale(1.02);
  box-shadow: 2px 3px 6px -4px;
}
