.div-principal {
  margin-left: 240px;
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
}

.top-panel-text {
  margin-left: 20px;
}

.addButton {
  padding-left: 16px !important;
  background-color: #cad401 !important;
  border-radius: 20px !important;
  height: 26px !important;
  width: 75px !important;
  font-weight: 700 !important;
  padding-bottom: 26px !important;
  display: flex !important;
}

.top-panel {
  top: 0px;
  font-weight: 500;
  font-size: 25px;
  padding: 10px;
  padding-bottom: 1.75%;
  display: flex;
  justify-content: unset;
  background-color: #ffffff;
}

.select-divider-search {
  display: inline-flex;
  justify-content: center;
  margin-left: 1%;
  margin-top: 1%;
}

.search-select {
  margin-left: 1%;
}

.button-search {
  background-color: #7e942f !important;
  border: none !important;
  color: white !important;
  width: 150px !important;
  padding: 0.5px !important;
  text-align: center !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
  border-radius: 17px !important;
  margin-left: 2% !important;
  font-size: 14px !important;
}

.button-search-disabled {
  background-color: #7e942f;
  opacity: 50%;
  border: none;
  color: white;
  width: 150px;
  padding: 0.5px;
  text-align: center;
  padding-left: 2%;
  padding-right: 2%;
  border-radius: 17px;
  margin-left: 2%;
  font-size: 14px;
}

.semana-div {
  background-color: #f5f5f5;
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 10px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.leaflet-container {
  width: 100%;
  height: 63%;
}

.bot-div {
  display: flex;
  justify-content: center;
}

.item-map {
  border: none;
  background-color: white;
  margin-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
}

.item-map-title {
  font-weight: 500;
  font-size: 16px;
}

.item-map-subtitle {
  font-size: 10px;
}

.item-map-subtitle2 {
  font-size: 10px;
  color: white;
}

.item-map-number {
  color: #ccd60f;
  font-size: 40px;
  font-weight: bold;
}

.popup-map {
  min-width: 370px;
}

.img-center {
  text-align: center;
  display: block;
}

.popup-white {
  width: 330px;
  display: flex;
  margin-left: 3px;
  justify-content: center;
  background: white;
}

.subpopup {
  display: flex;
  justify-content: center;
  margin-right: 7px;
}

.dia-container {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dia-container2 {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dia-container3 {
  display: flex;
  justify-content: center;
  color: black;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.plantation-title2 {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: -1%;
  margin: -1%;
}

.plantation-subtitle2 {
  font-size: 15px;
  font-weight: lighter;
  margin-bottom: 7%;
}

.plantation-subtitle3 {
  font-size: 12px;
  margin-bottom: 7%;
  margin-top: 3%;
}

.marker-button {
  background-color: #ccd60f;
  border: none;
  color: white;
  text-align: center;
  border-radius: 5px;
  width: 80%;
  font-weight: bold;
  font-size: 15px;
}
