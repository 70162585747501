.whiteContainer {
  background-color: white;
  padding-top: 20px;
  width: 95%;
  margin-left: 5%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.addVariety {
  border: 1.5px solid #dde1e6;
  width: 95% !important;
  border-radius: 2px;
  padding-bottom: 1%;
  margin-bottom: 1%;
  cursor: pointer;
}

.no-margin {
  margin-left: 0px !important;
}

.finishButton {
  background-color: #cad401 !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  width: 150px;
}

.cancelButton {
  background-color: white !important;
  color: #cad401 !important;
  border: 2px solid #cad401 !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  width: 150px;
}
.addButton {
  padding-left: 16px !important;
  background-color: #cad401 !important;
  border-radius: 20px !important;
  height: 26px !important;
  width: 75px !important;
  font-weight: 700 !important;
  padding-bottom: 26px !important;
  display: flex !important;
}

.circle {
  height: 15px;
  width: 15px;
  border-radius: 100px;
  margin-top: 8px;
}

.smallTable {
  line-height: 0.8;
}

.smallTable :hover {
  cursor: pointer;
}

.filterWeekInput {
  width: 50% !important;
  text-align: center;
}

.tooltipLineChart {
  background-color: #262338 !important;
  width: 9rem;
  padding: 10px;
  line-height: 80%;
  border-radius: 6px;
  color: white;
  height: 15vh;
  box-shadow: 2px 2px 5px 0px grey;
}

.meteo-kpi {
  background-color: #707070;
  border-radius: 6px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}
