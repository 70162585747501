.top-panelCO {
  top: 0px;
  font-weight: 500;
  font-size: 25px;
  padding: 10px;
  margin-left: 15px;
  height: 65px;
  background-color: #ffffff;
  display: flex;
}

.semana-divCO {
  background-color: #f5f5f5;
  margin-left: 62%;
  padding: 10px;
  font-size: 15px;
  height: 45px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.button-filter {
  background-color: #7e942f !important;
  border: none;
  color: white;
  border-radius: 20px !important;
  font-size: 13px;
  height: 32px;
  padding-top: 3px !important;
}

.dia-divCO {
  background-color: #cad401;
  color: white;
  text-align: center;
  margin-top: -5px;
  margin-left: 10px;
  border: none;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 15px;
  font-size: 15px;
  text-align: center;
}

.select-groupCO {
  margin-top: 20px;
  display: flex;
  background-color: #ffffff;
  height: 45px;
  width: 72%;
  padding-top: 6px;
  margin-left: 5%;
}

.select-groupCO2 {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  background-color: #ffffff;
  height: 45px;
  padding-top: 6px;
}

.link {
  color: #cad401;
  font-size: 18px;
}

.link:hover {
  color: #cad401;
  font-weight: bold;
}

.list-iconC {
  width: 75%;
}

.create-order1 {
  display: flex;
  margin-top: 30px;
}

.create-order2 {
  display: flex;
  margin-top: 3%;
}

.create-order3 {
  display: flex;
}

.date-pickerCO {
  width: 400px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100% !important;
}

.create-collapseCO {
  margin-top: 30px;
  margin-left: 2%;
}

.calibre-tableheadCO {
  height: 50px !important;
}

.calibre-tableCO {
  text-align: center;
  width: 600px !important;
  margin-left: 25px;
  margin-top: 10px !important;
}

.button-calibre {
  background-color: #e8e8e8 !important;
  border: none;
  font-weight: bold !important;
  color: #cad401 !important;
  border-radius: 20px !important;
  font-size: 13px;
  height: 32px;
  padding-top: 3px !important;
}

.dia-div3 {
  background-color: #cad401;
  color: white;
  text-align: center;
  margin-top: -5px;
  margin-left: 10px;
  border: none;
  width: 30px;
  height: 30px;
  padding-top: 4px;
  border-radius: 15px;
  font-size: 15px;
  text-align: center;
}

.semana-div3 {
  background-color: #f5f5f5;
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 10px;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.top-panel2 {
  top: 0px;
  font-weight: 500;
  font-size: 25px;
  padding: 10px;
  padding-bottom: 1.75%;
  display: flex;
  justify-content: unset;
  background-color: #ffffff;
}

.send-buttonCO {
  background-color: #ccd60f !important;
  border: none !important;
  color: white !important;
  text-align: center !important;
  border-radius: 20px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  width: 120px !important;
  margin-bottom: 5% !important;
}

.disabled-buttonCO {
  background-color: #e8e8e8 !important;
  border: none !important;
  color: white !important;
  text-align: center !important;
  border-radius: 20px !important;
  font-weight: bold !important;
  font-size: 15px !important;
  width: 120px !important;
  margin-bottom: 5% !important;
}

.iconsSize {
  width: 1rem;
  cursor: pointer;
}

.redirect-buttonCO {
  width: 200px !important;
  background-color: #cad401 !important;
  color: white !important;
  border-radius: 20px !important;
  margin-left: 2% !important;
}

.send-btnCO {
  background-color: #ccd60f !important;
  border: none !important;
  color: white !important;
  text-align: center !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.main-containersCO {
  background-color: white;
  height: 35vh;
  width: 37%;
  margin-left: 5%;
  margin-top: 6%;
}

.delete-CO {
  width: 35px;
  height: 35px;
  background-color: #c45438;
  margin-left: 5%;
  margin-top: 2%;
  border-radius: 50%;
}

.iconsBinSize {
  width: 1.3rem;
  cursor: pointer;
  margin-left: 7px;
  margin-top: 6px;
}

.main-containersHR {
  background-color: white;
  min-height: 40vh;
  width: 31%;
  margin-left: 5%;
  margin-top: 3%;
}

.main-containersHR2 {
  background-color: white;
  min-height: 40vh;
  width: 66%;
  margin-left: 3%;
  margin-top: 3%;
}

.div-codeHR {
  font-weight: bold;
  text-align: center;
  margin-top: 5%;
  padding-top: 6%;
}

.div-weekHR {
  font-weight: bold;
  text-align: center;
  margin-top: 2%;
  border-left: 2px solid #cad401;
  padding-top: 5%;
}

.main-containersHR3 {
  background-color: white;
  min-height: 32vh;
  width: 50%;
  margin-top: 2%;
}

.main-containersHR4 {
  background-color: white;
  min-height: 32vh;
  width: 46%;
  margin-left: 2%;
  margin-top: 2%;
}

.product-status {
  width: 2rem;
}

.icon-multimedia {
  width: 3rem;
}

.icon-multimedia2 {
  width: 3rem;
}

.image-carousel {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 45vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
