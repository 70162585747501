.disabled-buttonAF {
    background-color: #e8e8e8 !important;
    border: none !important;
    color: white !important;
    text-align: center !important;
    border-radius: 20px !important;
    font-weight: bold !important;
    font-size: 15px !important;
    width: 250px !important;
    height: 40px;
  }

  .disabled-buttonAF2 {
    background-color: #e8e8e8 !important;
    border: none !important;
    color: white !important;
    text-align: center !important;
    border-radius: 20px !important;
    font-weight: bold !important;
    font-size: 15px !important;
    width: 190px !important;
    height: 40px;
  }