.table1-container {
  display: flex;
  justify-content: center;
  margin-left: 170px;
  margin-bottom: 20px;
}

.resumen-button {
  width: 150px !important;
  background-color: #cad401 !important;
  color: white !important;
  border-radius: 20px !important;
  margin-left: 100% !important;
}

.resumen-button2 {
  width: 150px !important;
  height: 35px;
  background-color: #cad401 !important;
  color: white !important;
  border-radius: 20px !important;
  border: none;
}

.deleteButton {
  background-color: #d31e1e !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  width: 150px;
}

.graf-panel-purchase {
  padding: 5px;
  margin: 5px;
  margin-top: 1%;
  background-color: white;
  text-align: center;
}

.graf-panel-purchase3 {
  padding: 5px;
  margin: 5px;
  margin-top: 1%;
  background-color: white;
  text-align: center;
}

.graf2-panel-purchase {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.export-button {
  background-color: #7e942f !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  width: 150px;
  margin-left: 85% !important;
}

.export-button2 {
  background-color: #7e942f !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  width: 150px;
  margin-left: 80% !important;
}

.export-button3 {
  background-color: #7e942f !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  width: 150px;
  margin-left: 85% !important;
  margin-top: 1% !important;
}
