.leaflet-container4 {
  width: 100%;
  height: 100%;
}

.finca-panel4 {
  width: 54%;
  justify-content: center;
  padding: 10px;
  margin: 10px;
  background-color: white;
}

.middle-panel2 {
  height: 320px;
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
  margin-left: 3%;
  margin-right: 2%;
}

.button-variety {
  margin-bottom: 7%;
  color: black;
}
.varieties-container {
  margin: 10px;
  height: 250px;
  min-width: 400px;
  background-color: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.title-plant-container {
  background: white;
  margin-top: 2%;
  margin-left: 2.7%;
  padding: 10px;
  border: none;
  font-weight: bold;
  display: inline-flex;
}

.varieties-panel2 {
  border: none;
  background: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-quality {
  background-color: #cad401;
  border: none;
  color: white;
  padding: 5px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 500;
}

.var-title2 {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.certify-panel2 {
  min-height: 320px;
  max-height: 320px;
  width: 45%;
  padding: 10px;
  margin: 10px;
  background-color: white;
  text-align: center;
}
