.form-panel {
  margin: 3%;
  margin-bottom: 1%;
  padding: 3%;
  text-align: center;
  background: white;
  width: 70%;
}

.campos-panel {
  display: flex;
  justify-content: left;
  align-items: left;
  padding-bottom: 1%;
  background: white;
}

.subcampos-panel {
  padding-left: 3%;
  padding-right: 3%;
  text-align: left;
  background: white;
}

.subcampos-panel2 {
  text-align: left;
  background: white;
}

.form-select {
  text-align: left;
  margin-bottom: 7%;
  padding: 2%;
  padding-left: 6%;
  padding-right: 10%;
}

.title-form {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2%;
}

.title-form-masa {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2.5%;
}

.form-input {
  text-align: left;
  margin-bottom: 5%;
}

.enabled-button {
  background-color: #cad401;
  border: none;
  color: white;
  padding: 2px;
  text-align: center;
  margin-top: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 20px;
  font-size: 20px;
}

.disabled-button {
  background-color: #e8e8e8;
  border: none;
  color: white;
  padding: 2px;
  text-align: center;
  margin-top: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border-radius: 20px;
  font-size: 20px;
}

.send-button {
  background-color: #ccd60f;
  border: none;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
}

.footer-modal {
  display: flex;
  justify-content: center;
}
