/* html {
  background-color: #242424;
} */

.sidebar {
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #242424;
  color: #ffffff;
  border-bottom: none;
}

.sidebar2 {
  max-width: 500px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #242424;
  color: #ffffff;
  border-bottom: none;
}

.mui-selected {
  background-color: #b9aeae;
  color: #ffffff;
}

html {
  background: #242424;
}

.title-sidebar {
  font-size: 0.8rem;
}

.bot-bar {
  width: 240px;
  border-top: 2px solid rgb(160, 143, 143);
  background-color: #242424;
  color: #ffffff;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
  bottom: 0px;
  padding-right: 2%;
  position: absolute;
}
.top-bar {
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 20px;
}

.mid-bar {
  max-width: 240px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #cad401;
  color: white;
  font-weight: 500;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 1%;
}

.sidebar-subitem-text {
  font-size: 0.8rem;
}

.selected-tab {
  padding-left: 18;
  color: #070707;
}

.center-button {
  padding: 0px;
  background-color: purple;
}
