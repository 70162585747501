.login-form {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 20vh;
}

.login-panel {
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
}

.loginButton {
  background-color: #cad401 !important;
  border: none;
  color: white;
  border-radius: 20px;
  font-size: 15px;
  width: 180px !important;
  margin-left: 20%;
}

.passwordButton {
  width: 180px !important;
  background-color: #cad401 !important;
  color: white !important;
  border-radius: 20px !important;
  margin-left: 20%;
}

.logo {
  width: 120px;
  margin-left: 1%;
  margin-top: 1%;
}
